<template>
  <div class="shoplist-cnt">
    <loader-wrapper v-if="isLoading" />
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>Shops Listing</h1>
      </div>
    </header>
    <section class="mid-cnt">
      <ShopsCards :shops="shops" :userstatus="this.getUserInfo().status" @handleClick="handleShop" />
    </section>
  </div>
</template>
<script>
import BackIcon from '@/components/svgIcons/BackIcon';
import ApiRequest from '../services/ApiRequest';
import { BASE_API, LOCAL_SHOP_URL } from '../utils/constants';
import { GET } from '../utils/HttpStatusCodeConst';
import LoaderWrapper from '../components/commonUI/LoaderWrapper.vue';
import ShopsCards from '../components/ShopsCards.vue';
import { mapMutations } from 'vuex';
import { mapGetters } from "vuex";

export default {
  name: 'Shops',
  components: {
    BackIcon,
    LoaderWrapper,
    ShopsCards,
  },
  data() {
    return {
      shops: [],
      isLoading: false,
    };
  },

  methods: {
    ...mapMutations({
      setShopDetails: 'setShopDetails',
    }),
    ...mapGetters({
      getPartnerTasks: "getPartnerTasks",
      getUserInfo: "getUserInfo",
    }),

    getUnverifiedShops() {
      this.isLoading = true;
      console.log(this.getUserInfo().supportedpincode);
      ApiRequest(LOCAL_SHOP_URL, '/shopService/get-shop-list/'+this.getUserInfo().supportedpincode, GET)
        .then((data) => {
          this.shops = data;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          this.$swal({
            text: 'Something went wrong!',
            icon: 'error',
          });
        });
    },

    handleShop(shop) {
      this.isLoading = true;
      ApiRequest(BASE_API, `/get/verified/shops?shop_id=${shop.shop_id}`, GET)
        .then((data) => {
          this.isLoading = false;
          if (data && data.data && data.data.status == 'pending') {
            this.$swal({
              text: 'Your shop verification is pending!',
              icon: 'info',
            });
          } else if (data && data.data && data.data.status == 'accepted') {
            this.$swal({
              text: 'Your shop is verified!',
              icon: 'success',
            });
          } else {
            this.setShopDetails(shop);
            this.$router.push({
              path: 'verify-shop',
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          this.$swal({
            text: 'Something went wrong!',
            icon: 'error',
          });
        });
    },
  },

  mounted() {
    this.getUnverifiedShops();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/shop-listing.scss';
</style>
