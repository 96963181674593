<template>
  <ul class="shop-list">
    <li class="blank" v-if="shops.length == 0">{{ $t("noRecordFound") }}</li>
    <li v-else v-for="(shop, i) in shops" :key="i">
      <h2>{{ shop.name }}</h2>
      <div class="text-desc text-address">
        <span class="icon">
          <location-mark-icon />
        </span>
        {{
          shop.address.street +
          ", " +
          shop.address.city +
          ", " +
          "Pincode - " +
          shop.address.zip_code
        }}
      </div>

      <div class="text-desc text-mobile">
        <span class="icon">
          <mobile-icon />
        </span>
        {{ shop.address.phone }}
      </div>
      <p  v-if="userstatus!='partner_active'" style="font-size: 12px;color: #80808061;">Only active partner can verify</p>

      <div class="activeshop-blk" v-if="$route.path == '/qr-code'">
        <span class="shop-icon">
          <activated-shop-icon />
        </span>
        <span class="txt-active">Active Shop</span>
      </div>
      <div v-if="userstatus=='partner_active' && $route.path == '/shops'"
        class="action-button"
        @click="handleShop(shop)"
      >
        <span class="button verify-btn" > {{ $t("verifyShop") }} </span>
      </div>
      <div v-else class="action-button">
        <!-- <span class=" verify-btn" >Only active partner can verify</span> -->
      </div>
    </li>
  </ul>
</template>

<script>
import LocationMarkIcon from "@/components/svgIcons/LocationMarkIcon";
import MobileIcon from "@/components/svgIcons/MobileIcon";
import ActivatedShopIcon from "./svgIcons/ActivatedShopIcon.vue";
export default {
  props: ["shops","userstatus"],
  components: {
    LocationMarkIcon,
    MobileIcon,
    ActivatedShopIcon,
  },

  methods: {
    handleShop(shop) {
      this.$emit("handleClick", shop);
    },
  },
};
</script>

<style></style>
